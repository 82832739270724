import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import UserMainInfo from "../organisms/UserMainInfo";
import UserTapsNavigator from "../organisms/UserTapsNavigator";
import UserTapsTimeline from "../organisms/UserTapsTimeline";
import UserTapsSuggestions from "../organisms/UserTapsSuggestions";
import UserTapsSavedGames from "../organisms/UserTapsSavedGames";
import makeRequest from "../../api";
import Loader from "../atoms/Loader";
import ErrorMessage from "../atoms/ErrorMessage";
import Announcements from "../organisms/Announcements";

function User() {
  //Inbound Data
  const [userData, setUserData] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  //Outbound Data
  const { username } = useParams();
  // Others
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams({
    current_tap: "timeline",
  });
  const current_tap = searchParams.get("current_tap");

  //Resetting current tap search param to default if it's null
  useEffect(() => {
    if (current_tap === null) {
      setSearchParams({ current_tap: "timeline" });
    }
  }, [current_tap, setSearchParams]);

  /**
   * fetches the current users's info and stores it in the "data" variable
   */
  useEffect(() => {
    async function getCurrentUser() {
      setIsLoading(true);
      try {
        //Making request
        const responseData = await makeRequest.users.displayOneUser(username);
        if (!responseData[0].error) {
          setUserData(responseData);
          setErrorMessage(null);
          setIsLoading(false);
        } else {
          setUserData(null);
          setErrorMessage(responseData[0].error);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        return setErrorMessage(error.message);
      }
    }
    getCurrentUser();
  }, []);

  return (
    <div className="user">
      <Announcements />

      {isLoading ? <Loader /> : null}

      {errorMessage ? <ErrorMessage error={errorMessage} /> : null}

      {userData ? <UserMainInfo userData={userData} /> : null}

      <UserTapsNavigator
        userData={userData}
        current_tap={current_tap}
        setSearchParams={setSearchParams}
      />

      {userData && current_tap === "timeline" ? (
        <UserTapsTimeline userData={userData} />
      ) : null}

      {userData && current_tap === "suggestions" ? (
        <UserTapsSuggestions />
      ) : null}

      {userData && current_tap === "saved_games" ? (
        <UserTapsSavedGames />
      ) : null}
    </div>
  );
}

export default User;
