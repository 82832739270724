import React, { useState } from "react";
//Componets
import FormEditUserProfile from "./FormEditUserProfile";
import ButtonEditUserProfile from "../atoms/ButtonEditUserProfile";
import ButtonUserWatch from "../atoms/ButtonUserWatch";
import UserFollowersCounter from "../atoms/UserFollowersCounter";
import UserPlayedGamesCount from "../atoms/UserPlayedGamesCount";
import UrlCopyButton from "../molecules/UrlCopyButton";

function UserMainInfo(props) {
  //Inbound Data
  const data = props.userData;
  //Others
  const [enableEdit, setEnableEdit] = useState(false);

  /**
   * Creates component to be rendered
   * @returns JSX component
   */
  const Component = () => {
    //Layout if editing is disabled
    if (!enableEdit) {
      return (
        <div className="mainInfo__user">
          <div className="mainInfo__user__head">
            <div className="mainInfo__user__head__avatar">
              <img src={data[0].avatarUrl} alt="user avatar" />
            </div>

            <div className="mainInfo__user__head__overview">
              <div className="mainInfo__user__head__overview__name">
                <h1>
                  <strong>
                    {data[0].displayName
                      ? data[0].displayName
                      : data[0].userName}
                  </strong>
                </h1>
              </div>

              <div className="mainInfo__user__head__overview__stats">
                <UserPlayedGamesCount data={data} />
                <UserFollowersCounter data={data} />
              </div>
            </div>
          </div>

          <div className="mainInfo__user__actions">
            {localStorage.userid === props.userData[0].id ? null : (
              <ButtonUserWatch data={data} />
            )}

            <UrlCopyButton />
          </div>

          <div className="mainInfo__user__bio">
            <p>{data[0].bio}</p>
          </div>
        </div>
      );

      //Layout if editing is enabled
    } else if (enableEdit) {
      return (
        <FormEditUserProfile
          data={data}
          enableEdit={enableEdit}
          setEnableEdit={setEnableEdit}
        />
      );
    }
  };

  return (
    <section className="mainInfo">
      <div className="mainInfo__options">
        {/* <BackButton /> */}
        {data ? (
          <ButtonEditUserProfile
            data={data}
            enableEdit={enableEdit}
            setEnableEdit={setEnableEdit}
          />
        ) : null}
      </div>
      <Component />
    </section>
  );
}

export default UserMainInfo;
