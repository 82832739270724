import LoginForm from "../organisms/LoginForm";
import { Link } from "react-router-dom";

function Login() {
  return (
    <div className="sectionsContainer">
      <section className="login">
        <h1>Log in</h1>
        <div>
          <p>Don't have an account yet?</p>{" "}
          <div className="buttonSingupContainer">
            <Link to="/signup">
              <div className="buttonSignup">Sing up</div>
            </Link>
          </div>
        </div>
        <LoginForm />
        <div className="disclaimer">
          <p>By using this platform you agree to Gmology's </p>

          <a
            href="https://gmology.wordpress.com/2023/06/17/privacy-policy-for-gmology-com-june-2023/"
            target="_blank"
            rel="noopener noreferrer"
          >
            privacy policy.
          </a>
        </div>
      </section>
    </div>
  );
}

export default Login;
