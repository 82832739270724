import React, { useEffect, useState } from "react";
import makeRequest from "../../api";
import NotificationErrorBanner from "../molecules/NotificationErrorBanner";
import NotificationConfirmationBanner from "../molecules/NotificationConfirmationBanner";
import NotificationLoader from "../molecules/NotificationLoader";
import IconBookmark from "./IconBookmark";

type ButtonAddSaved_props = {
  game: {
    id: number;
    wasSaved: boolean;
  };
  negateWasSavedStatus: boolean;
  wasSaved: number[];
  setNegateWasSavedStatus: Function;
};

function ButtonAddSaved(props: ButtonAddSaved_props) {
  //Inbound Data
  const [errorMessage, setErrorMessage] = useState("");
  const [confirmationMessage, setConfirmationMessage] = useState("");
  //Outbound data
  const id = props.game.id;
  const currentGame = {
    game: {
      gameid: id,
    },
  };
  //Others
  const [isLoading, setIsLoading] = useState(false);
  const [wasSaved, setWasSaved] = useState<boolean>(resolveWasSavedStatus());

  /**
   * Accounts for different formats of wasSaved status coming from backend
   * Finds out was saved status
   * @returns wasSavedStatus boolean value
   */
  function resolveWasSavedStatus() {
    //is wasSaved status in props.games?
    if (props.game.wasSaved) {
      return props.game.wasSaved;
      //is wasSaved status in props?
    } else if (props.wasSaved && props.wasSaved.length > 0) {
      return true;
      //if no saved game status return "false"
    } else {
      return false;
    }
  }

  /**
   * Resets "Saved" button when user clicks on "add played game" button.
   * Adding a played game removes it from the "saved games" list in database.
   * This behavior is reflected in the front end with by the function bellow.
   */
  useEffect(() => {
    if (props.negateWasSavedStatus) {
      setWasSaved(false);
    }
    //Resetting the "removeWasPlayed" trigger to default state
    return () => {
      props.setNegateWasSavedStatus(false);
    };
  }, [props.negateWasSavedStatus]);

  /**
   * Removes the resource from users's list
   * @returns Array with object with message or error
   */
  async function removeSaved() {
    setIsLoading(true);
    setErrorMessage("");
    setConfirmationMessage("");
    try {
      const responseData = await makeRequest.savedGames.removeSaved(
        currentGame
      );
      if (!responseData[0].error) {
        setConfirmationMessage("Removed saved game");
        setErrorMessage("");
        setWasSaved(false);
        setIsLoading(false);
      } else {
        setConfirmationMessage("");
        setErrorMessage(responseData[0].error);
        setIsLoading(false);
      }
    } catch (error: any) {
      setIsLoading(false);
      setErrorMessage(error.message);
    }
  }

  /**
   * Adds the resource to users's list
   * @returns Array with object with message or error
   */
  async function addSaved() {
    setIsLoading(true);
    setErrorMessage("");
    setConfirmationMessage("");
    try {
      const responseData = await makeRequest.savedGames.addSaved(currentGame);
      if (!responseData[0].error) {
        setConfirmationMessage("Game saved");
        setErrorMessage("");
        setWasSaved(true);
        setIsLoading(false);
      } else {
        setConfirmationMessage("");
        setErrorMessage(responseData[0].error);
        setIsLoading(false);
      }
    } catch (error: any) {
      setIsLoading(false);
      setErrorMessage(error.message);
    }
  }

  /**
   * Triggers add or remove request and re-render of the parent component
   * @param {object} event click event information such as, input's current value
   */
  async function handleClick(event: any) {
    if (!localStorage.token) {
      return setErrorMessage("Sign up or log in");
    }
    if (wasSaved === true) {
      await removeSaved();
    } else {
      await addSaved();
    }
  }

  //Element to be rendered
  const button = () => {
    //If user has saved this games render and "add" button, otherwise a remove button
    if (!wasSaved) {
      return (
        <>
          <div className="buttonAddSaved">
            <div
              onClick={(event) => {
                handleClick(event);
              }}
            >
              <IconBookmark />
            </div>
          </div>
          <NotificationErrorBanner errorMessage={errorMessage} />
          <NotificationConfirmationBanner message={confirmationMessage} />
          <NotificationLoader isLoading={isLoading} />
        </>
      );
    } else {
      return (
        <>
          <div className="buttonRemoveSaved">
            <div
              onClick={(event) => {
                handleClick(event);
              }}
            >
              <IconBookmark />
            </div>
          </div>
          <NotificationErrorBanner errorMessage={errorMessage} />
          <NotificationConfirmationBanner message={confirmationMessage} />
          <NotificationLoader isLoading={isLoading} />
        </>
      );
    }
  };

  return button();
}

export default ButtonAddSaved;
