import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";

function IconDiscord() {
  return (
    <a
      href="https://discord.gg/KBef2nqevw"
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="iconDiscord">
        <FontAwesomeIcon icon={faDiscord} />
      </div>
    </a>
  );
}

export default IconDiscord;
