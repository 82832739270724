import React, { useState, useEffect } from "react";
import makeRequest from "../../api";
//Components
import ErrorMessage from "../atoms/ErrorMessage";
import Loader from "../atoms/Loader";
import ReferenceUserCard from "../molecules/ReferenceUserCard";

function ReferenceRandomUsers() {
  //inbound data
  const [data, setData] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  //Outbound data
  const desiredAmountofUsers = { limit: 4 };
  //Others
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Triggers data fetching when page has loaded
   */
  useEffect(() => {
    getReferenceUsers();
  }, []);

  /**
   * fetches the desired user's info
   */
  async function getReferenceUsers() {
    setIsLoading(true);
    try {
      const responseData = await makeRequest.users.getRandomUsers(
        desiredAmountofUsers
      );
      if (!responseData[0].error) {
        setData(responseData);
        setErrorMessage("");
      } else {
        setErrorMessage(responseData[0].error);
      }
    } catch (error) {
      let errorMessage = "Failed to get reference users";
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      return setErrorMessage(errorMessage);
    }
    setIsLoading(false);
  }

  return (
    <section className="referenceUsers">
      <div className="referenceUsers__title">
        <div className="referenceUsers__title__main">
          <h2>Random Users</h2>
        </div>
        <div className="referenceUsers__title__sub">
          <p>Check out what others have played</p>
        </div>
      </div>

      <div className="referenceUsers__list">
        {errorMessage !== "" ? <ErrorMessage error={errorMessage} /> : null}
        {isLoading ? <Loader /> : <ReferenceUserCard data={data} />}
      </div>
    </section>
  );
}

export default ReferenceRandomUsers;
