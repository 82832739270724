import React from "react";
import IconGrid from "../atoms/IconGrid";
import IconLightbulb from "../atoms/IconLightbulb";
import IconBookmark from "../atoms/IconBookmark";

type Taps_props = {
  userData: {
    id: string;
  }[];
  current_tap: string;
  setSearchParams: Function;
};

function Taps(props: Taps_props) {
  // Create a Map
  const tapNames = {
    A: "timeline",
    B: "suggestions",
    C: "saved_games",
  };
  //Element to be rendered
  const tapsSelector = () => {
    //Return element if id in memory matches current page's user
    if (props.userData && localStorage.userid === props.userData[0].id) {
      return (
        <section className="userPageTaps">
          <div className="tapsContainer">
            <div
              onClick={(e) => {
                props.setSearchParams({ current_tap: tapNames.A });
              }}
            >
              <IconGrid />
              {props.current_tap === tapNames.A ? (
                <div className="taps__under-bar" />
              ) : null}
            </div>

            <div
              onClick={(e) => {
                props.setSearchParams({ current_tap: tapNames.B });
              }}
            >
              <IconLightbulb />
              {props.current_tap === tapNames.B ? (
                <div className="taps__under-bar" />
              ) : null}
            </div>

            <div
              onClick={(e) => {
                props.setSearchParams({ current_tap: tapNames.C });
              }}
            >
              <IconBookmark />
              {props.current_tap === tapNames.C ? (
                <div className="taps__under-bar" />
              ) : null}
            </div>
          </div>
        </section>
      );
    } else {
      return null;
    }
  };

  return tapsSelector();
}

export default Taps;
