import { useState, useRef, useEffect } from "react";

/**
 * Mostly copied from codepen. Renders an image carousel.
 */
const ImageCarousel = ({ imageUrls, defaultSlideIndex = 0 }) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(defaultSlideIndex);
  const slidesRef = useRef([]);
  const imageTrackRef = useRef(null);
  let slideWidth = 0;
  useEffect(() => {
    slideWidth = slidesRef.current[0].getBoundingClientRect().width;
    slidesRef.current.forEach((slide, index) => {
      slide.style.left = slideWidth * index + "px";
    });
  }, [slidesRef.current]);

  useEffect(() => {
    const amountToMove = slidesRef.current[currentSlideIndex].style.left;
    imageTrackRef.current.style.transform = `translateX(-${amountToMove})`;
  }, [currentSlideIndex]);

  const clickLeftArrow = () => {
    setCurrentSlideIndex(Math.max(currentSlideIndex - 1, 0));
  };
  const clickRightArrow = () => {
    setCurrentSlideIndex(Math.min(currentSlideIndex + 1, imageUrls.length - 1));
  };
  const clickIndicator = (index) => {
    setCurrentSlideIndex(index);
  };

  return (
    <div className="imageCarouselContainer">
      <div
        className={
          "arrowButton leftArrow" +
          (currentSlideIndex === 0 ? " is-hidden" : "")
        }
        onClick={clickLeftArrow}
      >
        {"<"}
      </div>
      <div className="imageTrackContainer">
        <ul className="imageTrack" ref={imageTrackRef}>
          {imageUrls.map((url, index) => {
            return (
              <li
                key={index.toString()}
                className={
                  "imageSlide" +
                  (index === currentSlideIndex ? " currentSlide" : "")
                }
                ref={(el) => (slidesRef.current[index] = el)}
              >
                <img className="carouselImage" src={url} alt="" />
              </li>
            );
          })}
        </ul>
      </div>
      <div
        className={
          "arrowButton rightArrow" +
          (currentSlideIndex === imageUrls.length - 1 ? " is-hidden" : "")
        }
        onClick={clickRightArrow}
      >
        {">"}
      </div>
      <div className="indicatorNav">
        {imageUrls.map((url, index) => {
          return (
            <button
              className={
                "carouseIndicator" +
                (index === currentSlideIndex ? " currentSlide" : "")
              }
              onClick={() => clickIndicator(index)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ImageCarousel;
