import { useEffect, useState } from "react";
import ErrorMessage from "../atoms/ErrorMessage";

type notificationErrorBanner_props = {
  errorMessage: String;
};

function NotificationErrorBanner(props: notificationErrorBanner_props) {
  const [showNotification, setShowNotification] = useState(false);

  /**
   * Allows notification component to be seen when prop message changes
   */
  useEffect(() => {
    if (props.errorMessage !== "") {
      setShowNotification(true);
    }
  }, [props.errorMessage]);

  /**
   * If notification was shown makes it dissapear
   * after a set amount of time
   */
  useEffect(() => {
    if (showNotification) {
      setTimeout(() => {
        setShowNotification(false);
      }, 3000);
    }
  }, [showNotification]);

  const component = () => {
    //if there is a message return the notification banner else return nothing
    if (showNotification) {
      return (
        <div className="notificationErrorBanner">
          <ErrorMessage error={props.errorMessage} />
        </div>
      );
    } else {
      return null;
    }
  };

  return component();
}

export default NotificationErrorBanner;
