import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import makeRequest from "../../api";
import NotificationErrorBanner from "../molecules/NotificationErrorBanner";
import NotificationConfirmationBanner from "../molecules/NotificationConfirmationBanner";
import NotificationLoader from "../molecules/NotificationLoader";

type buttonAddPlayedSmall_props = {
  game: {
    id: number;
    wasPlayed: boolean;
  };
};

function ButtonAddPlayedSmall(props: buttonAddPlayedSmall_props) {
  //Inbound Data
  const [errorMessage, setErrorMessage] = useState("");
  const [confirmationMessage, setConfirmationMessage] = useState("");
  //Outbound data
  const id = props.game.id;
  const currentGame = {
    user: {
      gameid: id,
    },
  };

  //Others
  const [isLoading, setIsLoading] = useState(false);
  const [wasPlayed, setWasPlayed] = useState(() => {
    if (props.game.wasPlayed) {
      return props.game.wasPlayed;
    } else {
      return false;
    }
  });

  /**
   * Removes the resource from users's list
   * @returns Array with object with message or error
   */
  async function removePlayed() {
    setIsLoading(true);
    setErrorMessage("");
    setConfirmationMessage("");
    try {
      const responseData = await makeRequest.playedGames.removePlayed(
        currentGame
      );
      if (!responseData[0].error) {
        setConfirmationMessage(responseData[0].message);
        setErrorMessage("");
        setWasPlayed(false);
        setIsLoading(false);
      } else {
        setConfirmationMessage("");
        setErrorMessage(responseData[0].error);
        setIsLoading(false);
      }
    } catch (error: any) {
      setIsLoading(false);
      setErrorMessage(error.message);
    }
  }

  /**
   * Adds the resource to users's list
   * @returns Array with object with message or error
   */
  async function addPlayed() {
    setIsLoading(true);
    setErrorMessage("");
    setConfirmationMessage("");
    try {
      const responseData = await makeRequest.playedGames.addPlayed(currentGame);
      if (!responseData[0].error) {
        setConfirmationMessage(responseData[0].message);
        setErrorMessage("");
        setWasPlayed(true);
        setIsLoading(false);
      } else {
        setConfirmationMessage("");
        setErrorMessage(responseData[0].error);
        setIsLoading(false);
      }
    } catch (error: any) {
      setIsLoading(false);
      setErrorMessage(error.message);
    }
  }

  /**
   * component that renders either an add or remove button depending on state
   */
  const component = () => {
    if (wasPlayed) {
      return (
        <div
          className="buttonRemovePlayedSmall"
          onClick={() => {
            removePlayed();
          }}
        >
          <FontAwesomeIcon icon={faCheck} />
          <NotificationErrorBanner errorMessage={errorMessage} />
          <NotificationConfirmationBanner message={confirmationMessage} />
          <NotificationLoader isLoading={isLoading} />
        </div>
      );
    } else {
      return (
        <div
          className="buttonAddPlayedSmall"
          onClick={() => {
            addPlayed();
          }}
        >
          <FontAwesomeIcon icon={faPlus} />
          <NotificationErrorBanner errorMessage={errorMessage} />
          <NotificationConfirmationBanner message={confirmationMessage} />
          <NotificationLoader isLoading={isLoading} />
        </div>
      );
    }
  };

  return component();
}

export default ButtonAddPlayedSmall;
