import React from "react";
import { Link } from "react-router-dom";
import SmallRoundAvatar from "./SmallRoundAvatar";
import ErrorMessage from "../atoms/ErrorMessage";

type ReferenceUserCard_props = {
  data: {
    id: string;
    userName: string;
    displayName: string;
    avatarUrl: string;
    bio: string;
  }[];
};

/**
 * Dinamically maps all fectched users as JSX blocks ready to be rendered.
 * If there is no data to render, returns placeholder JSX block.
 */
function ReferenceUserCard(props: ReferenceUserCard_props) {
  if (props.data) {
    let referenceUsers = props.data.map((user) => {
      try {
        //Returning default reference users
        return (
          <Link key={user.id} to={`/users/${user.userName}`}>
            <div className="refUser">
              <SmallRoundAvatar avatarUrl={user.avatarUrl} />

              <div className="refUser__text">
                <div className="refUser__text__main">
                  <h4>{user.displayName ? user.displayName : user.userName}</h4>
                </div>

                <div className="refUser__text__sub">
                  <p>{user.bio}</p>
                </div>
              </div>
            </div>
          </Link>
        );
      } catch (error) {
        let errorMessage = "Failed to get reference users";
        if (error instanceof Error) {
          errorMessage = error.message;
        }
        return <ErrorMessage key={user.id} error={errorMessage} />;
      }
    });

    return referenceUsers;
  } else {
    return (
      <div className="referenceUser">
        <div className="referenceUser__text">
          <p>No reference users available</p>
        </div>
      </div>
    );
  }
}

export default ReferenceUserCard;
