import React from "react";
import IconChevronDown from "../atoms/IconChevronDown";

type expandButton_props = {
  setSearchParams: Function;
};

function expandButton(props: expandButton_props) {
  return (
    <div
      className="expandButton"
      onClick={() => {
        props.setSearchParams((prev: any) => {
          prev.set("wasExpanded", "true");
          return prev;
        });
      }}
    >
      <IconChevronDown />
    </div>
  );
}

export default expandButton;
