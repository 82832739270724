import React from "react";
import footerLogoColor from "../../assets/logo_color_bg.webp";
import IconTwitter from "../atoms/IconTwitter";
import IconPaypal from "../atoms/IconPaypal";
import IconPatreon from "../atoms/IconPatreon";
import IconEnvelope from "../atoms/IconEnvelope";
import IGDBLogo from "../../assets/IGDB_logo.png";
import IconTwitch from "../atoms/IconTwitch";
import IconTumblr from "../atoms/IconTumblr";
import IconWordpress from "../atoms/IconWordpress";
import IconThreads from "../atoms/IconThreads";
import IconDiscord from "../atoms/IconDiscord";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="footerContainer">
      <footer className="footer">
        <div className="footer__imageContainer">
          <img src={footerLogoColor} alt="gmology logo" />
        </div>

        <div className="footer__support">
          <div className="footer__support__title">
            <h4>Support Us!</h4>
          </div>
          <div className="footer__support__text">
            <p>
              Gmology started as a one person effort. If you would like to
              support the site please consider joining our Patreon or donating
              through the link below. Thank you!
            </p>
          </div>

          <div className="footer__support__links">
            <div className="footer__support__links__patreon">
              <IconPatreon />
              <div className="footer__support__links__patreon__text">
                <p>
                  <a
                    href="https://www.patreon.com/gmology"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <input type={"button"} value={"Patreon"} />
                  </a>
                </p>
              </div>
            </div>

            <div className="footer__support__links__paypal">
              <IconPaypal />
              <form
                className="donateButton"
                action="https://www.paypal.com/donate"
                method="post"
                target="_top"
              >
                <input type="hidden" name="business" value="54NPU9Y3YJQRN" />
                <input type="hidden" name="amount" value="3" />
                <input type="hidden" name="no_recurring" value="0" />
                <input
                  type="hidden"
                  name="item_name"
                  value="Fund Gmology.com! Help the site stay up and grow."
                />
                <input type="hidden" name="currency_code" value="USD" />
                <input
                  type="image"
                  src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif"
                  border="0"
                  name="submit"
                  title="PayPal - The safer, easier way to pay online!"
                  alt="Donate with PayPal button"
                />
                <img
                  alt=""
                  border="0"
                  src="https://www.paypal.com/en_US/i/scr/pixel.gif"
                  width="1"
                  height="1"
                />
              </form>
            </div>
          </div>
        </div>

        <div className="footer__contact">
          <div className="footer__contact__title">
            <h4>Contact & Social</h4>
          </div>

          <div className="footer__contact__email">
            <div className="footer__contact__email__emailIcon">
              <IconEnvelope />
            </div>

            <div className="footer__contact__email__text">
              <Link to={"/contact-us"}>
                <p>Send us a message</p>
              </Link>
            </div>
          </div>

          <div className="footer__contact__twitter">
            <IconTwitter />

            <div className="footer__contact__twitter__text">
              <a
                href="https://twitter.com/gmologyofficial"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p>@gmologyOfficial </p>
              </a>
            </div>
          </div>

          <div className="footer__contact__tumblr">
            <IconTumblr />

            <div className="footer__contact__tumblr__text">
              <a
                href="https://gmology.tumblr.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p>Daily inspiration!</p>
              </a>
            </div>
          </div>

          {/* <div className="footer__contact__threads">
            <IconThreads />

            <div className="footer__contact__threads__text">
              <a
                href="https://www.threads.net/@gmologyofficial"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p>@GmologyOfficial</p>
              </a>
            </div>
          </div> */}

          <div className="footer__contact__discord">
            <IconDiscord />

            <div className="footer__contact__discord__text">
              <a
                href="https://discord.gg/KBef2nqevw"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p>Gmology on Discord</p>
              </a>
            </div>
          </div>
        </div>

        <div className="footer__blog">
          <div className="footer__blog__title">
            <h4>Blog</h4>
          </div>

          <div className="footer__blog__wordpress">
            <div className="footer__blog__wordpress__wordpressIcon">
              <IconWordpress />
            </div>

            <div className="footer__blog__wordpress__text">
              <a
                href="https://gmology.wordpress.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p>Visit the blog</p>
              </a>
            </div>
          </div>
        </div>

        <div className="footer__blog">
          <div className="footer__blog__title">
            <h4>More</h4>
          </div>

          <div className="footer__blog__wordpress">
            <div className="footer__blog__wordpress__text">
              <a
                href="https://gmology.wordpress.com/2023/06/17/privacy-policy-for-gmology-com-june-2023/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p>Privacy Policy</p>
              </a>
            </div>
          </div>
        </div>

        <div className="footer__credits">
          <div className="footer__credits__text">
            <p>
              Gmology.com copyright 2023. All rights reserved. Third party
              copyrighted material featured in this platform such as game cover
              art and more belong to their respective owners. Powered by
              Twitch's IGDB.
            </p>
          </div>

          <div className="footer__credits__twitch">
            <IconTwitch />
            <div className="footer__credits__twitch__IGDBIcon">
              <a
                href="https://www.igdb.com/"
                target={"_blank"}
                rel="noopener noreferrer"
              >
                <img src={IGDBLogo} alt={"IGDB logo"} />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
