import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSliders } from "@fortawesome/free-solid-svg-icons";

function IconSliders() {
  return (
    <div className="iconSliders icon2">
      <FontAwesomeIcon icon={faSliders} />
    </div>
  );
}

export default IconSliders;
