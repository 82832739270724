import React, { useEffect, useState } from "react";
import makeRequest from "../../api";
import NotificationErrorBanner from "../molecules/NotificationErrorBanner";
import NotificationConfirmationBanner from "../molecules/NotificationConfirmationBanner";
import NotificationLoader from "../molecules/NotificationLoader";

type ButtonAddPlayedMedium_props = {
  game: {
    id: number;
    wasPlayed: boolean;
  };
  setNegateWasSavedStatus: Function;
};

function ButtonAddPlayedMedium(props: ButtonAddPlayedMedium_props) {
  //Inbound Data
  const [errorMessage, setErrorMessage] = useState("");
  const [confirmationMessage, setConfirmationMessage] = useState("");
  //Outbound data
  const id = props.game.id;
  const currentGame = {
    user: {
      gameid: id,
    },
  };

  //Others
  const [isLoading, setIsLoading] = useState(false);
  const [wasPlayed, setWasPlayed] = useState(props.game.wasPlayed);

  /**
   * Removes the resource from users's list
   * @returns Array with object with message or error
   */
  async function removePlayed() {
    setIsLoading(true);
    setErrorMessage("");
    setConfirmationMessage("");
    try {
      const responseData = await makeRequest.playedGames.removePlayed(
        currentGame
      );
      if (!responseData[0].error) {
        setConfirmationMessage(responseData[0].message);
        setErrorMessage("");
        setWasPlayed(false);
        setIsLoading(false);
      } else {
        setConfirmationMessage("");
        setErrorMessage(responseData[0].error);
        setIsLoading(false);
      }
    } catch (error: any) {
      setIsLoading(false);
      setErrorMessage(error.message);
    }
  }

  /**
   * Adds the resource to users's list
   * @returns Array with object with message or error
   */
  async function addPlayed() {
    setIsLoading(true);
    setErrorMessage("");
    setConfirmationMessage("");
    try {
      const responseData = await makeRequest.playedGames.addPlayed(currentGame);
      if (!responseData[0].error) {
        setConfirmationMessage(responseData[0].message);
        setErrorMessage("");
        setWasPlayed(true);
        props.setNegateWasSavedStatus(true);
        setIsLoading(false);
      } else {
        setConfirmationMessage("");
        setErrorMessage(responseData[0].error);
        setIsLoading(false);
      }
    } catch (error: any) {
      setIsLoading(false);
      setErrorMessage(error.message);
    }
  }

  /**
   * Triggers add or remove request and re-render of the parent component
   * @param {object} event click event information such as, input's current value
   */
  async function handleClick(event: any) {
    if (!localStorage.token) {
      return setErrorMessage("Sign up or log in");
    }
    if (event.target.value === "Played!") {
      const remove = await removePlayed();
    } else {
      const add = await addPlayed();
    }
  }

  //Element to be rendered
  const button = () => {
    //If user has played this games render and "add" button, otherwise a remove button
    if (!wasPlayed) {
      return (
        <div className="buttonAddPlayed">
          <form>
            <input
              type={"button"}
              value="Add Played"
              onClick={(event) => {
                handleClick(event);
              }}
            />
          </form>
          <NotificationErrorBanner errorMessage={errorMessage} />
          <NotificationConfirmationBanner message={confirmationMessage} />
          <NotificationLoader isLoading={isLoading} />
        </div>
      );
    } else {
      return (
        <div className="buttonRemovePlayed">
          <form>
            <input
              type={"button"}
              value="Played!"
              onClick={(event) => {
                handleClick(event);
              }}
            />
          </form>
          <NotificationErrorBanner errorMessage={errorMessage} />
          <NotificationConfirmationBanner message={confirmationMessage} />
          <NotificationLoader isLoading={isLoading} />
        </div>
      );
    }
  };

  return button();
}

export default ButtonAddPlayedMedium;
