import React from "react";
import Icon from "../../assets/custom_icons/1top-B_48x48.png";

function TopOneIconB() {
  return (
    <div className="topOneIcon">
      <img src={Icon} alt="1top game" />
    </div>
  );
}

export default TopOneIconB;
