import React, { useEffect, useState } from "react";
import makeRequest from "../../api";
import NotificationErrorBanner from "../molecules/NotificationErrorBanner";
import NotificationConfirmationBanner from "../molecules/NotificationConfirmationBanner";
import NotificationLoader from "../molecules/NotificationLoader";

type ButtonAddPlayed_props = {
  data: [
    {
      games: [
        {
          id: number;
          cover: {
            id: number;
            url: string;
          };
          first_release_date: number;
          genres: [{ id: number; name: string }];
          involved_companies: [
            {
              id: number;
              company: {
                id: number;
                name: string;
              };
            }
          ];
          name: string;
          platforms: [
            {
              id: number;
              name: string;
            }
          ];
          summary: string;
          total_rating: number;
        }
      ];
      playCount: Object;
      top1Count: Object;
      wasPlayed: number[];
    }
  ];
  wasPlayed: boolean;
  setWasPlayed: Function;
  setNegateWasSavedStatus: Function;
};

function ButtonAddPlayed(props: ButtonAddPlayed_props) {
  //Inbound Data
  const [errorMessage, setErrorMessage] = useState("");
  const [confirmationMessage, setConfirmationMessage] = useState("");
  //Outbound data
  const id = props.data[0].games[0].id;
  const currentGame = {
    user: {
      gameid: id,
    },
  };

  //Others
  const [isLoading, setIsLoading] = useState(false);
  const [wasPlayed, setWasPlayed] = useState(() => {
    //Find out if game is in "wasPlayed" array from retrieved data
    const gameWasPlayed = props.data[0].wasPlayed.find(
      () => props.data[0].games[0].id
    );
    if (gameWasPlayed) {
      return true;
    } else {
      return false;
    }
  });

  /**
   * Syncs props.wasPlayed to this component's own wasPlayed state.
   * that way, other components can know if wasPlayed state changed.
   * For example, the "add top" selector uses that information
   * to display and update correctly.
   */
  useEffect(() => {
    props.setWasPlayed(wasPlayed);
  }, []);

  /**
   * Syncs wasPlayed state to props.wasPlayed.
   * If another componet removes the game from user's played games
   * this effect should update this component to reflect the change.
   */
  useEffect(() => {
    setWasPlayed(props.wasPlayed);
  }, [props.wasPlayed]);

  /**
   * Removes the resource from users's list
   * @returns Array with object with message or error
   */
  async function removePlayed() {
    setIsLoading(true);
    setErrorMessage("");
    setConfirmationMessage("");
    try {
      const responseData = await makeRequest.playedGames.removePlayed(
        currentGame
      );
      if (!responseData[0].error) {
        setConfirmationMessage(responseData[0].message);
        setErrorMessage("");
        setWasPlayed(false);
        props.setWasPlayed(false);
        setIsLoading(false);
      } else {
        setConfirmationMessage("");
        setErrorMessage(responseData[0].error);
        setIsLoading(false);
      }
    } catch (error: any) {
      setIsLoading(false);
      setErrorMessage(error.message);
    }
  }

  /**
   * Adds the resource to users's list
   * @returns Array with object with message or error
   */
  async function addPlayed() {
    setIsLoading(true);
    setErrorMessage("");
    setConfirmationMessage("");
    try {
      const responseData = await makeRequest.playedGames.addPlayed(currentGame);
      if (!responseData[0].error) {
        setConfirmationMessage(responseData[0].message);
        setErrorMessage("");
        setWasPlayed(true);
        props.setWasPlayed(true);
        props.setNegateWasSavedStatus(true);
        setIsLoading(false);
      } else {
        setConfirmationMessage("");
        setErrorMessage(responseData[0].error);
        setIsLoading(false);
      }
    } catch (error: any) {
      setIsLoading(false);
      setErrorMessage(error.message);
    }
  }

  /**
   * Triggers add or remove request and re-render of the parent component
   * @param {object} event click event information such as, input's current value
   */
  async function handleClick(event: any) {
    if (!localStorage.token) {
      return setErrorMessage("Sign up or log in");
    }
    if (event.target.value === "Played!") {
      const remove = await removePlayed();
    } else {
      const add = await addPlayed();
    }
  }

  //Element to be rendered
  const button = () => {
    //If user has played this games render and "add" button, otherwise a remove button
    if (!wasPlayed) {
      return (
        <div className="buttonAddPlayed">
          <form>
            <input
              type={"button"}
              value="Add Played"
              onClick={(event) => {
                handleClick(event);
              }}
            />
          </form>
          <NotificationErrorBanner errorMessage={errorMessage} />
          <NotificationConfirmationBanner message={confirmationMessage} />
          <NotificationLoader isLoading={isLoading} />
        </div>
      );
    } else {
      return (
        <div className="buttonRemovePlayed">
          <form>
            <input
              type={"button"}
              value="Played!"
              onClick={(event) => {
                handleClick(event);
              }}
            />
          </form>
          <NotificationErrorBanner errorMessage={errorMessage} />
          <NotificationConfirmationBanner message={confirmationMessage} />
          <NotificationLoader isLoading={isLoading} />
        </div>
      );
    }
  };

  return button();
}

export default ButtonAddPlayed;
