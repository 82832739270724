import React from "react";
import FormContact from "../organisms/FormContact";

function Contact() {
  return (
    <div className="sectionsContainer">
      <section className="contactUs">
        <h1>Contact Us</h1>
        <p>We can't wait to hear from you!</p>
        <FormContact />
      </section>
    </div>
  );
}

export default Contact;
