import React from "react";
import CreateAccountForm from "../organisms/CreateAccountForm";
import { Link } from "react-router-dom";

function CreateAccount() {
  return (
    <div className="sectionsContainer">
      <section className="createAccount">
        <h1>Sign Up</h1>
        <p>
          Already have an account? <Link to="/login">Log in</Link> instead.
        </p>
        {/* <div className="buttonLoginContainer">
          <Link to="/login">
            <div className="buttonLogin">Log in</div>
          </Link>
        </div> */}
        <hr />
        <CreateAccountForm />
      </section>
    </div>
  );
}

export default CreateAccount;
