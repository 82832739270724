import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";

function ButtonVerticalEllipsis() {
  return (
    <div className="buttonVerticalEllipsis">
      <div>
        <FontAwesomeIcon className="iconEllipsis" icon={faEllipsisVertical} />
      </div>
    </div>
  );
}

export default ButtonVerticalEllipsis;
