import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLightbulb } from "@fortawesome/free-solid-svg-icons";

function IconLightbulb() {
  return (
    <div className="IconLightbulb">
      <div className="icon2">
        <FontAwesomeIcon icon={faLightbulb} />
      </div>
    </div>
  );
}

export default IconLightbulb;
