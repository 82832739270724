import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTableCells } from "@fortawesome/free-solid-svg-icons";

function IconGrid() {
  return (
    <div className="IconGrid">
      <div className="icon2">
        <FontAwesomeIcon icon={faTableCells} />
      </div>
    </div>
  );
}

export default IconGrid;
