import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

function IconCancel() {
  return (
    <div className="iconCancel">
      <FontAwesomeIcon icon={faXmark} />
    </div>
  );
}

export default IconCancel;
