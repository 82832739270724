import React, { useEffect } from "react";
import image_topCount from "../../assets/gmology_benefits_topCount.png";
import image_chronology from "../../assets/gmology_benefits_chronology.png";
import image_hoard from "../../assets/gmology_benefits_hoard.png";
import image_share from "../../assets/gmology_benefits_share.png";
import image_gmologyLogo from "../../assets/logo_color_bg.webp";
import image_likesClaps from "../../assets/gmology_benefits_likesClaps.png";
import image_top10 from "../../assets/gmology_benefits_top10.png";
import explainer1 from "../../assets/explainer_1_v2.png";
import explainer2 from "../../assets/explainer_2_v2.png";
import explainer3 from "../../assets/explainer_3_v2.png";
import explainer4 from "../../assets/explainer_4_v2.png";
import logoTwitch from "../../assets/logo_twitch.png";
import iconIGDB from "../../assets/IGDB_logo.png";
import image_barebones from "../../assets/gmology_screenshot_user_colored.png";
//components
import { Link, useNavigate } from "react-router-dom";
import ReferenceCollections from "../organisms/ReferenceCollections";
import ImageCarousel from "../organisms/ImageCarousel";
import ReferenceFeaturedUsers from "../organisms/ReferenceFeaturedUsers";
import ReferenceRandomUsers from "../organisms/ReferenceRandomUsers";

function Homepage() {
  //Defining images for carousel
  const imageUrls = [explainer1, explainer2, explainer3, explainer4];

  //Redirecting to profile page if user has data in local storage and user hasn`t clicked on main logo
  const redirect = useNavigate();
  useEffect(() => {
    if (localStorage.userName && !sessionStorage.userClickedMainLogo) {
      redirect(`/users/${localStorage.userName}`);
    }
  });

  return (
    <div className="homepage">
      <section className="giveawayNotice">
        <div className="giveawayNotice__text">
          Gmology is going out of service! On 12/31/2024 our platform will go away and all user data will be deleted shortly after. 
          Sadly we failed to raise interest from the general public. Thank you very much for your support, Goodbye!
        </div>
      </section>

      <section className="headline">
        <div className="headline__background">
          <div className="headline__background--purple">
            <div className="headline__background--black">
              <div className="headline__logo">
                <div>
                  <img src={image_gmologyLogo} alt="gmology logo" />
                </div>
              </div>

              <div className="headline__title">
                <div>
                  <h1>EVERYONE'S GAMING HISTORY</h1>
                </div>
              </div>

              <div className="startNow__signUp">
                <Link to={"signup"}>
                  <input
                    className="homepagesignUpButton"
                    type={"button"}
                    value={"Start Now"}
                  ></input>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="homepageCarousel"></section> */}

      <ReferenceFeaturedUsers />
      <ReferenceRandomUsers />

      <section className="productDetails">
        <div className="productDetails__whatIsIt">
          {/* <div className="productDetails__whatIsIt__title">
            <h3>What Is Gmology?</h3>
          </div> */}
          <ImageCarousel imageUrls={imageUrls} />
          <div className="productDetails__whatIsIt__description">
            <h2>
              {" "}
              Showcase a beautiful timeline with all the games you've ever
              played{" "}
            </h2>
            <p>
              Create your gaming timeline, easily share it, connect with fellow
              gamers and explore their own timelines. We are a cozy community
              for long-time gamers where your gaming history takes center stage.
              We are the gaming community's collective memory!
            </p>
          </div>
        </div>

        <div className="productDetails__howItWorks">
          {/* <div className="productDetails__howItWorks__title">
            <h3>How it Works</h3>
          </div> */}

          <div className="productDetails__howItWorks__video">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/hbB3cI1AUsI"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </section>

      <ReferenceCollections />

      <section className="benefits">
        <div className="benefits__top10">
          <img src={image_top10} alt={"benefit illustration"} />
          <h2>Never Forget Your Top 10</h2>
          <p>
            You want your favorite games set in Internet stone or your followers
            to know your passions in a nutshell, Gmology's got you.
          </p>
        </div>

        <div className="benefits__chronology">
          <img src={image_chronology} alt={"benefit illustration"} />
          <h2>Feel the accomplishment!</h2>
          {/* <h4>(aka your gmology)</h4> */}
          <p>
            See where your gaming journey has taken you in a{" "}
            <strong> beautiful, neat and tidy</strong> autogenerated timeline
            and watch it grow as you grow.
          </p>
        </div>

        <div className="benefits__share">
          <img src={image_share} alt={"Chonology screenshot"} />
          <h2>Share with the world</h2>
          <p>
            With a <strong>custom URL</strong> you can share your gmology with
            friends and followers all across the internet.
          </p>
        </div>

        <div className="benefits__likesClaps">
          <img src={image_likesClaps} alt={"benefit illustration"} />
          <h2>Show Appreciation!</h2>
          <p>
            If you like someone's gmology let your special gamer know by{" "}
            <strong>leaving a like or follow </strong> (a.k.a watch) them to
            easily find them later.
          </p>
        </div>

        <div className="benefits__barebones">
          <img src={image_barebones} alt={"benefit illustration"} />
          <h2>Barebones Approach</h2>
          <p>
            Unlike other sites, Gmology is clean and simple. It's just about
            you, your top 10 and your timeline.
          </p>
        </div>

        {/* <div className="benefits__hoard">
          <img src={image_hoard} alt={"Chonology screenshot"} />
          <h2>Hoard without hoarding</h2>
          <p>
            Want to own all those <strong>games that left a mark on you</strong>
            , but that's just way too many? Add them to your gmology to feel
            that same sense of accomplishment.
          </p>
        </div> */}

        <div className="benefits__topCount">
          <img src={image_topCount} alt={"benefit illustration"} />
          <h2>Cool Stats</h2>
          <p>
            See how many more people agree that your top #1 game is the absolute
            best of all time.
          </p>
        </div>
      </section>

      <section className="poweredBy">
        <div>
          <h2>Powered by</h2>
        </div>
        <div className="poweredBy__icons">
          <img src={logoTwitch} alt={"igdb logo"} />
          <img src={iconIGDB} alt={"igdb logo"} />
        </div>
        <div>
          <p>
            Building a Gmology is no small task. You don't want to spend hours
            on it just to realize that the games you care about are missing. So
            we are using the{" "}
            <strong>most powerful videogame database around</strong>, IGDB,
            provided and maintained by the Twitch people.
          </p>
        </div>
      </section>

      <section className="startNow">
        <div className="startNow__signUp">
          <Link to={"signup"}>
            <input
              className="homepagesignUpButton"
              type={"button"}
              value={"Start Now"}
            ></input>
          </Link>
        </div>
      </section>

      <section className="footNotes">
        <p></p>
      </section>
    </div>
  );
}

export default Homepage;
