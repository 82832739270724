import ImageNotAvailable from "../atoms/ImageNotAvailable";
import { convertUnixToYear } from "../../functions";
import { Link } from "react-router-dom";
// Components
import UsersList from "./UsersList";
import ButtonAddPlayedSmall from "../atoms/ButtonAddPlayedSmall";

function SearchPreviewCard(props) {
  //Data to loop over
  let games = props.data.games;
  let users = props.data.users;
  //Determining which of the two data sets to pick
  let showUsersResults = props.showUsersResults;

  /**
   * Triggers redirection of user to desired page by updating parent states
   * @param {*} redirectPath
   */
  function handleClick() {
    props.setSearchQuery("");
  }

  //Creating each element from games data
  let gameListCards = games.slice(0, 5).map((game) => {
    //Defing the path for redirection when user clicks on the game
    let redirectPath = `/games/${game.id}`;

    //Getting the year
    const year = convertUnixToYear(game);
    //Laying out platforms names as a text string
    let platforms = "- ";
    getPlatforms();
    function getPlatforms() {
      if (game.platforms) {
        game.platforms.map((platform) => {
          return (platforms = platforms + platform.name + " . ");
        });
      }
    }

    return (
      <div className="gameListCard" key={game.id}>
        <Link to={redirectPath}>
          <article
            className="gameListCard__wrapper"
            onClick={() => {
              handleClick();
            }}
          >
            <div className="gameListCard__wrapper__imageContainer">
              {game.cover ? (
                <img src={game.cover.url} alt="Game cover art" />
              ) : (
                <ImageNotAvailable />
              )}
            </div>

            <div className="gameListCard__wrapper__text">
              <div className="gameListCard__wrapper__text__titleWrapper">
                <div className="gameListCard__wrapper__text__titleWrapper__title">
                  <p>{game.name}</p>
                </div>
              </div>

              <div className="gameListCard__wrapper__text__infoWrapper">
                <div className="gameListCard__wrapper__text__infoWrapper__year">
                  <p>{year ? year : null}</p>
                </div>

                <div className="gameListCard__wrapper__text__infoWrapper__platforms">
                  <p>{platforms}</p>
                </div>
              </div>
            </div>
          </article>
        </Link>

        <ButtonAddPlayedSmall game={game} />
      </div>
    );
  });

  return (
    <>
      {showUsersResults ? (
        <UsersList users={users} setSearchQuery={props.setSearchQuery} />
      ) : (
        gameListCards
      )}
    </>
  );
}

export default SearchPreviewCard;
