import React from "react";
import ReferenceCollections from "./ReferenceCollections";
import ReferenceRandomUsers from "./ReferenceRandomUsers";
import ReferenceFeaturedUsers from "./ReferenceFeaturedUsers";

function UserSuggestions() {
  return (
    <div className="suggestions">
      <ReferenceFeaturedUsers />
      <ReferenceCollections />
      <ReferenceRandomUsers />
    </div>
  );
}

export default UserSuggestions;
