import { Link } from "react-router-dom";
import ImageNotAvailable from "../atoms/ImageNotAvailable";
import ButtonAddPlayedMedium from "../atoms/ButtonAddPlayedMedium";
import ButtonAddSaved from "../atoms/ButtonAddSaved";
import ButtonVerticalEllipsis from "../atoms/ButtonVerticalEllipsis";
import GamePopUpMenu from "../organisms/GamePopUpMenu";
import { useState } from "react";

function SearchResultCard(props) {
  const [negateWasSavedStatus, setNegateWasSavedStatus] = useState(false);
  const [showOptions, setShowOptions] = useState(false);

  return (
    <article className="searchResultCard" key={props.game.id}>
      <Link to={props.redirectPath}>
        <div className="searchResultCard__imageContainer">
          {props.game.cover ? (
            <img src={props.url} alt="Game cover art" />
          ) : (
            <ImageNotAvailable />
          )}
        </div>
      </Link>

      <div className="searchResultCard__columnB">
        <Link to={props.redirectPath}>
          <div className="searchResultCard__columnB__info">
            <div className="searchResultCard__columnB__info__title">
              <p>{props.game.name}</p>
            </div>

            <div className="searchResultCard__columnB__info__other">
              <div className="searchResultCard__columnB__info__other__year">
                <p>{props.year ? props.year : null}</p>
              </div>

              <div className="searchResultCard__columnB__info__other__platforms">
                <p>{props.platforms}</p>
              </div>
            </div>
          </div>
        </Link>

        <div className="searchResultCard__columnB__actions">
          <div className="searchResultCard__columnB__actions__leftButtons">
            <ButtonAddPlayedMedium
              game={props.game}
              setNegateWasSavedStatus={setNegateWasSavedStatus}
            />

            <div
              className="searchResultCard__columnB__actions__leftButtons__more"
              onClick={() => {
                setShowOptions(!showOptions);
              }}
            >
              <ButtonVerticalEllipsis />
            </div>
          </div>

          {showOptions ? (
            <GamePopUpMenu
              gameid={props.game.id}
              name={props.game.name}
              showOptions={showOptions}
              setShowOptions={setShowOptions}
            />
          ) : null}

          <ButtonAddSaved
            game={props.game}
            negateWasSavedStatus={negateWasSavedStatus}
            setNegateWasSavedStatus={setNegateWasSavedStatus}
          />
        </div>
      </div>
    </article>
  );
}

export default SearchResultCard;
