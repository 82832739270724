import { useState, FormEvent } from "react";
import { useSearchParams } from "react-router-dom";
import SubmitButton from "../atoms/SubmitButton";
import makeRequest from "../../api";
import NotificationLoader from "../molecules/NotificationLoader";
import NotificationConfirmationBanner from "../molecules/NotificationConfirmationBanner";
import NotificationErrorBanner from "../molecules/NotificationErrorBanner";

function FormContact() {
  //Inbound data
  const [errorMessage, setErrorMessage] = useState("");
  const [confiramtionMessage, setConfiramtionMessage] = useState("");
  //Getting predefined message subject
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamsSubject = searchParams.get("subject");
  //Outbound data
  const [nameValue, setNameValue] = useState("");
  const [fromEmailValue, setFromEmailValue] = useState("");
  const [emailBodyValue, setEmailBodyValue] = useState("");
  const [subjectValue, setSubjectValue] = useState(
    searchParamsSubject ? searchParamsSubject : ""
  );
  const userInput = {
    mail: {
      name: nameValue,
      fromEmail: fromEmailValue,
      subject: subjectValue,
      emailBody: emailBodyValue,
    },
  };
  //Others
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Sends email
   */
  async function sendMail(event: FormEvent) {
    //Prevent page from reloading when clicking submit
    event.preventDefault();
    try {
      setIsLoading(true);
      setErrorMessage("");
      //making api request
      const responseData = await makeRequest.contact.sendContactMail(userInput);

      //handling response
      if (!responseData[0].error && !responseData[0].errors) {
        //setting confirmation message
        setConfiramtionMessage(responseData[0].message);
        setErrorMessage("");
        //resetting form
        setFromEmailValue("");
        setNameValue("");
        setSubjectValue("");
        setEmailBodyValue("");
        //dismising loader
        setIsLoading(false);

        //Handling errors
      } else {
        if (responseData[0].error) {
          setErrorMessage(responseData[0].error);
          setConfiramtionMessage("");
          setIsLoading(false);
        }
      }
    } catch (error: any) {
      setErrorMessage(error.message);
      setIsLoading(false);
    }
  }

  return (
    <form
      className="contactUs_form"
      onSubmit={(event) => {
        sendMail(event);
      }}
    >
      <NotificationLoader isLoading={isLoading} />
      <NotificationConfirmationBanner message={confiramtionMessage} />
      <NotificationErrorBanner errorMessage={errorMessage} />

      <label>
        Name
        <input
          type="text"
          maxLength={50}
          value={nameValue}
          onChange={(event) => {
            setNameValue(event.target.value);
          }}
          required
        />
      </label>

      <label>
        Email
        <input
          type="email"
          value={fromEmailValue}
          onChange={(event) => {
            setFromEmailValue(event.target.value);
          }}
          required
        />
      </label>

      <label>
        Subject
        <input
          type="text"
          maxLength={100}
          value={subjectValue}
          required
          onChange={(event) => {
            setSubjectValue(event.target.value);
          }}
        />
      </label>

      <label>
        Message
        <textarea
          maxLength={1000}
          value={emailBodyValue}
          onChange={(event) => {
            setEmailBodyValue(event.target.value);
          }}
          required
        />
      </label>

      <SubmitButton />
    </form>
  );
}

export default FormContact;
