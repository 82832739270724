import React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import makeRequest from "../../api";
import Loader from "../atoms/Loader";
import ErrorMessage from "../atoms/ErrorMessage";
import SearchResultCard from "../molecules/SearchResultCard";

type games = {
  id: number;
  cover: {
    id: number;
    url: string;
  };
  first_release_date: number;
  wasSaved: boolean;
  name: string;
  platforms: { id: number; name: string }[];
}[];

function UserSavedGames() {
  //Inbound Data
  const [games, setGames] = useState<games>([]); //saved games
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageForCounter, setErrorMessageForCounter] = useState("");
  //Outbound data
  const [page, setPage] = useState(1);
  const userInput = {
    user: {
      userid: localStorage.userid,
    },
  };
  //Others
  const [isLoading, setIsLoading] = useState(false);
  const [savedGamesCount, setSavedGamesCount] = useState(0);

  /**
   * Fetches amount of fetched games
   */
  useEffect(() => {
    async function getSavedGamesCount() {
      try {
        setIsLoading(true);
        //Making request
        const responseData = await makeRequest.savedGames.getSavedGamesCount(
          userInput
        );
        if (!responseData[0].error) {
          setSavedGamesCount(responseData[0].savedGamesCount);
          setErrorMessageForCounter("");
          setIsLoading(false);
        } else {
          setSavedGamesCount(0);
          setErrorMessageForCounter(responseData[0].error);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        let errorMessage = "";
        if (error instanceof Error) {
          errorMessage = error.message;
        }
        return setErrorMessageForCounter(errorMessage);
      }
    }

    getSavedGamesCount();
  }, []);

  /**
   * Fetches user's saved games on load
   */
  useEffect(() => {
    async function getUserSavedGames() {
      try {
        setIsLoading(true);
        //Making request
        const responseData = await makeRequest.games.get_user_saved_games(page);
        if (!responseData[0].error) {
          const newData = responseData;
          setGames(newData);
          setErrorMessage("");
          setIsLoading(false);
        } else if (
          responseData[0].error &&
          responseData[0].error === "Resource not found"
        ) {
          setGames([]);
          setErrorMessage("");
          setIsLoading(false);
        } else {
          setErrorMessage(responseData[0].error);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        let newErrorMessage = "";
        if (error instanceof Error) {
          newErrorMessage = error.message;
        }
        return setErrorMessage(newErrorMessage);
      }
    }

    getUserSavedGames();
  }, []);

  /**
   * Updates games fetched to include more results
   */
  useEffect(() => {
    //Run effect only if this is not the first render
    if (page > 1) {
      getMoreUserSavedGames();
    }

    async function getMoreUserSavedGames() {
      try {
        setIsLoading(true);
        //Making request
        const responseData = await makeRequest.games.get_user_saved_games(page);
        if (!responseData[0].error) {
          const newData = [...games, ...responseData];
          setGames(newData);
          setErrorMessage("");
          setIsLoading(false);
        } else if (
          responseData[0].error &&
          responseData[0].error == "Resource not found"
        ) {
          setGames([]);
          setErrorMessage("");
          setIsLoading(false);
        } else {
          setErrorMessage(responseData[0].error);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        let errorMessage = "";
        if (error instanceof Error) {
          errorMessage = error.message;
        }
        return setErrorMessage(errorMessage);
      }
    }
  }, [page]);

  //Creating each element from games data
  let gameCards = games.map((game) => {
    let url;
    if (game.cover) {
      url = game.cover.url.split("t_thumb").join("t_cover_small_2x");
    }

    let redirectPath = `/games/${game.id}`;

    const releaseDate = game.first_release_date;
    const date = new Date(releaseDate * 1000);
    const year = date.toLocaleDateString("en-US").toString().split("/")[2];

    //Laying out platforms names as a text string
    let platforms = "";
    getPlatforms();
    function getPlatforms() {
      if (game.platforms) {
        game.platforms.map((platform) => {
          return (platforms = platforms + platform.name + " . ");
        });
      }
    }

    return (
      <div key={game.id}>
        <hr className="separator" />
        <SearchResultCard
          game={game}
          url={url}
          year={year}
          platforms={platforms}
          redirectPath={redirectPath}
        />
      </div>
    );
  });

  return (
    <section className="savedGames listOfGames">
      {isLoading ? <Loader /> : null}
      {errorMessage !== "" ? <ErrorMessage error={errorMessage} /> : null}
      {errorMessageForCounter !== "" ? (
        <ErrorMessage error={errorMessageForCounter} />
      ) : null}

      <p>You've Saved {savedGamesCount} Games</p>

      {games.length === 0 ? null : gameCards}

      {games.length >= 50 ? (
        <form>
          {isLoading ? <Loader /> : null}
          {errorMessage !== "" ? <ErrorMessage error={errorMessage} /> : null}
          <input
            className="LoadMoreButton"
            type={"button"}
            value="Load more"
            onClick={() => {
              setPage(page + 1);
            }}
          />
        </form>
      ) : null}
    </section>
  );
}

export default UserSavedGames;
