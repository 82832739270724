import { useEffect, useState } from "react";
import makeRequest from "../../api";
import { createChronology } from "../../functions";
//components
import Loader from "../atoms/Loader";
import ErrorMessage from "../atoms/ErrorMessage";
import Decade from "./Decade";
import Filters from "./Filters";

function UserPlayedGames(props) {
  //Inbound Data
  const [data, setData] = useState(props.data); //played games
  const [errorMessage, setErrorMessage] = useState(null);
  //Outbound data
  const [page, setPage] = useState(1);
  const userInput = {
    user: {
      userid: props.userData[0].id,
    },
  };
  //Others
  const [isLoading, setIsLoading] = useState(false);
  const [chronologyFromData, setChronologyFromData] = useState([]);
  const [filterOptions, setFilterOptions] = useState({
    highlightOwnedGames: false,
  });

  /**
   * Organizes games into a chronology when component loads
   */
  useEffect(() => {
    try {
      setIsLoading(true);
      setErrorMessage(null);
      setIsLoading(false);
      //creating a cronology out of newly received data
      setChronologyFromData(createChronology(data));
    } catch (error) {
      setIsLoading(false);
      setChronologyFromData(null);
      return setErrorMessage(error.message);
    }
  }, []);

  /**
   * Updates games fetched to include more results
   */
  useEffect(() => {
    //Run effect only if this is not the first render
    if (page > 1) {
      getUserGames();
    }

    async function getUserGames() {
      try {
        setIsLoading(true);
        //Making request
        const responseData = await makeRequest.games.get_user_games(
          userInput,
          page
        );
        if (!responseData[0].error) {
          const newData = [...data, ...responseData];
          setData(newData);
          setErrorMessage(null);
          setIsLoading(false);
          //creating a cronology out of newly received data
          setChronologyFromData(createChronology(newData));
        } else if (
          responseData[0].error &&
          responseData[0].error == "Resource not found"
        ) {
          setData(null);
          setErrorMessage(null);
          setIsLoading(false);
        } else {
          // setData(null);
          setErrorMessage(responseData[0].error);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        return setErrorMessage(error.message);
      }
    }
  }, [page]);

  /**
   * Creates component to be rendered
   * @returns JSX component
   */
  const Chronology = () => {
    //Creating each element from games data
    let decades = chronologyFromData.map((decade) => {
      try {
        if (decade.games.length !== 0) {
          return (
            <Decade
              key={decade.decadeName}
              decade={decade}
              filterOptions={filterOptions}
            />
          );
        }
      } catch (error) {
        return <ErrorMessage key={decade.decadeName} error={error.message} />;
      }
    });

    return decades;
  };

  return (
    <section className="playedGames">
      {isLoading ? <Loader /> : null}

      <div className="playedGames__title">
        <p>
          {props.userData[0].displayName
            ? props.userData[0].displayName
            : props.userData[0].userName}
          's gmology
        </p>
      </div>

      <Filters
        filterOptions={filterOptions}
        setFilterOptions={setFilterOptions}
      />

      {chronologyFromData.length > 0 ? <Chronology /> : null}

      {errorMessage ? <ErrorMessage error={errorMessage} /> : null}

      {data.length >= 200 ? (
        <form>
          <input
            className="LoadMoreButton"
            type={"button"}
            value="Load more"
            onClick={() => {
              setPage(page + 1);
            }}
          />
        </form>
      ) : null}
    </section>
  );
}

export default UserPlayedGames;
