import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookmark } from "@fortawesome/free-solid-svg-icons";

function IconBookmark() {
  return (
    <div className="IconBookmark">
      <div className="icon2">
        <FontAwesomeIcon icon={faBookmark} />
      </div>
    </div>
  );
}

export default IconBookmark;
