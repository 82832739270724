import Loader from "../atoms/Loader";

type notificationLoader__props = {
  isLoading: boolean;
};

function NotificationLoader(props: notificationLoader__props) {
  const component = () => {
    if (props.isLoading) {
      return (
        <div className="notificationLoader">
          <Loader />
        </div>
      );
    } else {
      return null;
    }
  };
  return component();
}

export default NotificationLoader;
