import React from "react";

function NoteBanner(props) {
  return (
    <div className="noteBanner">
      <p>
        To edit visit{" "}
        <a href="https://www.igdb.com" target="blank" rel="noreferrer">
          igdb.com
        </a>
        . Changes will reflect on Gmology.
      </p>
    </div>
  );
}

export default NoteBanner;
