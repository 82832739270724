import { useEffect, useState } from "react";
import IconSliders from "../atoms/IconSliders";
import IconToggle from "../atoms/IconToggle";

type filters_props = {
  filterOptions: {
    highlightOwnedGames: boolean;
  };
  setFilterOptions: Function;
};

function Filters(props: filters_props) {
  const [showOptions, setShowOptions] = useState(false);

  /**
   * Defines what happens when user clicks on filter buttons
   */
  function handleClick() {
    //Using updated prop to update state of parent component
    props.setFilterOptions({
      highlightOwnedGames: !props.filterOptions.highlightOwnedGames,
    });
  }

  return (
    <div className="filters">
      <div
        onClick={() => {
          setShowOptions(!showOptions);
        }}
      >
        <IconSliders />
      </div>

      {showOptions ? (
        <ul className="filters__options">
          <li
            data-name={"highlightOwnedGames"}
            onClick={() => {
              handleClick();
            }}
          >
            <IconToggle />
            Highlight owned games
          </li>
        </ul>
      ) : null}
    </div>
  );
}

export default Filters;
