import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

function IconCopy(props) {
  return (
    <div className="iconCopy icon3">
      <FontAwesomeIcon icon={faCopy} />
    </div>
  );
}

export default IconCopy;
