import React from "react";
import { useState } from "react";
import IconCopy from "../atoms/IconCopy";

function UrlCopyButton() {
  const [wasCopied, setWasCopied] = useState(false);

  /**
   * Captures the URL withouth the search parameters
   */
  function copyURL() {
    const completeUrl = window.location.href;
    const url = completeUrl.split("?");
    navigator.clipboard.writeText(url[0]);
    setWasCopied(true);
  }

  return (
    <div
      className={wasCopied ? "UrlCopy--copied" : "UrlCopy"}
      onClick={() => copyURL()}
    >
      <IconCopy />
      <div>{wasCopied ? "Copied!" : "Copy URL"}</div>
    </div>
  );
}

export default UrlCopyButton;
