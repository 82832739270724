import { useState, useEffect } from "react";
import UserTopGames from "./UserTopGames";
import UserPlayedGames from "./UserPlayedGames";
import UserPlayedGamesLIkes from "./UserPlayedGamesLIkes";
import UserNoGamesLayout from "./UserNoGamesLayout";
import Loader from "../atoms/Loader";
import ErrorMessage from "../atoms/ErrorMessage";
import makeRequest from "../../api";

function Timeline({ userData }) {
  //Inbound Data
  const [data, setData] = useState([]); //played games
  const [errorMessage, setErrorMessage] = useState(null);
  //Outbound data
  const [page] = useState(1);
  const userInput = {
    user: {
      userid: userData[0].id,
    },
  };
  //Others
  const [isLoading, setIsLoading] = useState(false);

  /**
   * fetches the current users's played games
   */
  useEffect(() => {
    async function getUserGames() {
      try {
        setIsLoading(true);
        //Making request
        const responseData = await makeRequest.games.get_user_games(
          userInput,
          page
        );
        if (!responseData[0].error) {
          setData(responseData);
          setErrorMessage(null);
          setIsLoading(false);
        } else if (
          responseData[0].error &&
          responseData[0].error == "Resource not found"
        ) {
          setData(null);
          setErrorMessage(null);
          setIsLoading(false);
        } else {
          setData(null);
          setErrorMessage(responseData[0].error);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        return setErrorMessage(error.message);
      }
    }
    getUserGames();
  }, []);

  /**
   * Rendering condition layout for this component.
   * When user has played games it will render timeline.
   * Otherwise will render placeholder layout
   * @returns JSX element containing desired layout
   */
  const component = () => {
    if (data && data.length > 0) {
      return (
        <>
          <UserTopGames userData={userData} />
          <UserPlayedGames userData={userData} data={data} />
          <UserPlayedGamesLIkes userData={userData} />
        </>
      );
    } else {
      return <UserNoGamesLayout userData={userData} />;
    }
  };

  return (
    <div className="timeline">
      {isLoading ? <Loader /> : null}
      {errorMessage ? <ErrorMessage error={errorMessage} /> : null}
      {component()}
    </div>
  );
}

export default Timeline;
