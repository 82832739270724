import { useEffect, useState } from "react";
import ConfirmationMessage from "../atoms/ConfirmationMessage";

type notificationConfirmationBanner_props = {
  message: String;
};

function NotificationConfirmationBanner(
  props: notificationConfirmationBanner_props
) {
  const [showNotification, setShowNotification] = useState(false);

  /**
   * Allows notification component to be seen when prop message changes
   */
  useEffect(() => {
    if (props.message !== "") {
      setShowNotification(true);
    }
  }, [props.message]);

  /**
   * If notification was shown makes it dissapear
   * after a set amount of time
   */
  useEffect(() => {
    if (showNotification) {
      setTimeout(() => {
        setShowNotification(false);
      }, 2000);
    }
  }, [showNotification]);

  const component = () => {
    //if there is a message return the notification banner else return nothing
    if (showNotification) {
      return (
        <div className="notificationConfirmationBanner">
          <ConfirmationMessage message={props.message} />
        </div>
      );
    } else {
      return null;
    }
  };

  return component();
}

export default NotificationConfirmationBanner;
