import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

function IconChevronDown() {
  return (
    <div>
      <FontAwesomeIcon icon={faChevronDown} />
    </div>
  );
}

export default IconChevronDown;
