import { Link } from "react-router-dom";

function Announcements() {
  return (
    <section className="notice">
      <div className="notice__text">
        <p>
          <Link to={"/contact-us"}>Send us Your Feedback</Link>
        </p>
      </div>
    </section>
  );
}

export default Announcements;
