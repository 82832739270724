import React from "react";
import IconChevronUp from "../atoms/IconChevronUp";

type collapseButton_props = { setSearchParams: Function };

function collapseButton(props: collapseButton_props) {
  return (
    <div
      className="collapseButton"
      onClick={() => {
        props.setSearchParams((prev: any) => {
          prev.set("wasExpanded", "false");
          return prev;
        });
      }}
    >
      <IconChevronUp />
    </div>
  );
}

export default collapseButton;
