import React from "react";
import imagetopGames from "../../assets/gmology_benefits_top10_2.png";
import ReferenceCollections from "./ReferenceCollections";
import ReferenceRandomUsers from "./ReferenceRandomUsers";

function UserNoGamesLayout(props) {
  return (
    <>
      <section className="noGamesLayout">
        <div>
          <h2>Nothing here yet</h2>
        </div>
        <div>
          {props.userData[0].id == localStorage.userid ? (
            <p>
              Use search bar at the top of the page to find games or see
              recommendations below.
            </p>
          ) : null}
        </div>
        <div>
          <img src={imagetopGames} />
        </div>
      </section>

      {props.userData[0].id == localStorage.userid ? (
        <>
          <ReferenceCollections />
          <ReferenceRandomUsers />
        </>
      ) : null}
    </>
  );
}

export default UserNoGamesLayout;
