import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faToggleOn, faToggleOff } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

function IconToggle() {
  const [toogleOn, setToogleOn] = useState(false);

  return (
    <div
      className="iconToggle icon1"
      onClick={() => {
        setToogleOn(!toogleOn);
      }}
    >
      {toogleOn ? (
        <FontAwesomeIcon icon={faToggleOn} />
      ) : (
        <FontAwesomeIcon icon={faToggleOff} />
      )}
    </div>
  );
}

export default IconToggle;
