/**
 * creates a new array with original data minus already deleted items
 * @param {array} originalData Data to remove things from
 * @param {array} removedObejctsIDs Data to be removed
 * @param {string} resourceType Resource, e.g. post, user, comment
 * @returns a new array based on the original but without removed things
 */
export function dataWitouthRemovedItems(
  originalData,
  removedObejctsIDs,
  resourceType
) {
  resourceType = resourceType + "_id";
  //Checking that nothing has been removed yet
  if (removedObejctsIDs.length === 0) {
    return originalData;
    //otherwise proceeding with new array
  } else {
    let newData = [];

    //Looping over original data
    for (let index = 0; index < originalData.length; index++) {
      let object = originalData[index];
      let match = false;

      //comparing each orignial data element against removed items
      for (let i = 0; i < removedObejctsIDs.length; i++) {
        let removedObjectID = removedObejctsIDs[i];
        if (object[resourceType] == removedObjectID) {
          match = true;
        }
      }
      //If there is at the end of the comarison there is no match add object to new array
      if (match === false) {
        //adding objects to a new array
        newData.push(object);
        //Adding deleted object with a tag (in the form of a new key)
      } else {
        object.deleted = true;
        newData.push(object);
      }
    }
    return newData;
  }
}

// const x = [{"id":987,"cover":{"id":90048,"url":"//images.igdb.com/igdb/image/upload/t_thumb/co1xhc.jpg"},"first_release_date":1190678400,"name":"Halo 3"},{"id":21865,"cover":{"id":154021,"url":"//images.igdb.com/igdb/image/upload/t_thumb/co3aud.jpg"},"first_release_date":1353196800,"name":"Call of Duty: Black Ops II"},{"id":28204,"cover":{"id":82135,"url":"//images.igdb.com/igdb/image/upload/t_thumb/co1rdj.jpg"},"first_release_date":1509580800,"name":"Call of Duty: WWII"},{"id":141503,"cover":{"id":171645,"url":"//images.igdb.com/igdb/image/upload/t_thumb/co3ofx.jpg"},"first_release_date":1636416000,"name":"Forza Horizon 5"}];

// console.log(createChronology(x));

/**
 * Organizes data by year and decade
 * @param {array} originalData Data to work from
 * @returns a array with one object per decade. each object contains an array of game objects.
 */
export function createChronology(originalData) {
  try {
    //Checking that there is data to work on
    if (originalData.length == 0) {
      return originalData;
    } else {
      const newData = [
        {
          decadeName: 1950,
          games: [],
        },
        {
          decadeName: 1960,
          games: [],
        },
        {
          decadeName: 1970,
          games: [],
        },
        {
          decadeName: 1980,
          games: [],
        },
        {
          decadeName: 1990,
          games: [],
        },
        {
          decadeName: 2000,
          games: [],
        },
        {
          decadeName: 2010,
          games: [],
        },
        {
          decadeName: 2020,
          games: [],
        },
        {
          decadeName: 2030,
          games: [],
        },
        {
          decadeName: 2040,
          games: [],
        },
        {
          decadeName: 2050,
          games: [],
        },
        {
          decadeName: 2060,
          games: [],
        },
        {
          decadeName: "Unknown",
          games: [],
        },
      ];

      //Seding each element in the original data to corresponding array
      for (let index = 0; index < originalData.length; index++) {
        const game = originalData[index];

        if (!game.first_release_date) {
          newData[12].games.push(game);
        } else {
          //Figuring out game's year
          const year = convertUnixToYear(game);

          //Fitting into corresponding decade in "newData" array
          for (let index = 0; index < newData.length; index++) {
            const decade = newData[index];

            //decade has to be the same or higher that game's year
            if (year >= decade.decadeName && year < decade.decadeName + 10) {
              decade.games.push(game);
            }
          }
        }
      }

      return newData;
    }
  } catch (error) {
    console.log(error);
    return [{ error: error.message }];
  }
}

/**
 * Converts unix timestamp to a year number
 * @param {OBJECT} data Data to work from
 * @returns {NUMBER} year
 */
export function convertUnixToYear(data) {
  if (data.first_release_date) {
    const releaseDate = data.first_release_date;
    const date = new Date(releaseDate * 1000);
    const year = date.toLocaleDateString("en-US").toString().split("/")[2];
    return year;
  } else {
    return null;
  }
}
