import React, { useRef, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
//components
import PlayedGameCard from "../molecules/PlayedGameCard";
import ExpandButton from "./ExpandButton";
import CollapseButton from "./CollapseButton";

function Decade(props) {
  const limit = 48;
  //creating search param
  const [SearchParams, setSearchParams] = useSearchParams({});
  const WasExpandedParam = SearchParams.get("wasExpanded");
  //Limit games rendered per decade if wasExpanded is "false"
  const gamesPerDecade = WasExpandedParam === "true" ? Infinity : limit;

  /**
   * Returns an arrow down if decade is collapsed or arrow up if not
   * @returns a colapsable arrow icon component
   */
  const component = () => {
    if (WasExpandedParam === "true") {
      return <CollapseButton setSearchParams={setSearchParams} />;
    } else {
      return <ExpandButton setSearchParams={setSearchParams} />;
    }
  };

  return (
    <div className="playedGames__decade">
      <div className="playedGames__decade__title">
        <h2>
          {" "}
          {props.decade.decadeName !== "Unknown"
            ? props.decade.decadeName + "s"
            : props.decade.decadeName}{" "}
        </h2>
        <div>
          {" "}
          <span>{props.decade.games.length}</span>
        </div>
      </div>

      <div>
        <hr />
      </div>

      {/* Creating game cards inside each decade */}
      <div className="playedGames__decade__cards">
        {props.decade.games.slice(0, gamesPerDecade).map((game) => {
          return (
            <PlayedGameCard
              key={game.id}
              game={game}
              filterOptions={props.filterOptions}
            />
          );
        })}
      </div>

      {/* Render callapse arrow icon if there are more games than
      can be seen */}
      {props.decade.games.length > limit ? component() : null}
    </div>
  );
}

export default Decade;
