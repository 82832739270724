import { Link } from "react-router-dom";
import IconCancel from "../atoms/IconCancel";
import { useEffect, useState } from "react";
import Loader from "../atoms/Loader";
import makeRequest from "../../api";
import NotificationErrorBanner from "../molecules/NotificationErrorBanner";
import NotificationConfirmationBanner from "../molecules/NotificationConfirmationBanner";

type gamePopUpMenu_props = {
  gameid: string;
  name: string;
  showOptions: boolean;
  setShowOptions: Function;
};

function GamePopUpMenu(props: gamePopUpMenu_props) {
  //Inbound Data
  const [errorMessage, setErrorMessage] = useState("");
  const [confirmationMessage, setConfirmationMessage] = useState("");
  //Outbound data
  const currentGame = {
    game: {
      gameid: props.gameid,
    },
  };
  //Other
  const [isLoading, setIsLoading] = useState(false);
  const [isOwned, setIsOwned] = useState(false);

  useEffect(() => {
    if (localStorage.token) {
      getIsOwnedStatus();
    }
  }, []);

  /**
   * Determines if game is in user's owned games list
   */
  async function getIsOwnedStatus() {
    try {
      setIsLoading(true);
      setErrorMessage("");
      setConfirmationMessage("");
      const responseData = await makeRequest.ownedGames.isOwned(currentGame);
      if (!responseData[0].error) {
        setErrorMessage("");
        setIsOwned(responseData[0].isOwned);
        setIsLoading(false);
      } else {
        setErrorMessage(responseData[0].error);
        setIsLoading(false);
      }
    } catch (error: any) {
      setIsLoading(false);
      setErrorMessage(error.message);
    }
  }

  /**
   * Adds the resource to users's owned games list
   * @returns Array with object with message or error
   */
  async function addOwned() {
    setIsLoading(true);
    setErrorMessage("");
    setConfirmationMessage("");
    try {
      const responseData = await makeRequest.ownedGames.addOwned(currentGame);
      if (!responseData[0].error) {
        setConfirmationMessage(responseData[0].message);
        setErrorMessage("");
        setIsOwned(true);
        setIsLoading(false);
      } else {
        setConfirmationMessage("");
        setErrorMessage(responseData[0].error);
        setIsLoading(false);
      }
    } catch (error: any) {
      setIsLoading(false);
      setErrorMessage(error.message);
    }
  }

  /**
   * Removes the resource from users's owned games list
   * @returns Array with object with message or error
   */
  async function removeOwned() {
    setIsLoading(true);
    setErrorMessage("");
    setConfirmationMessage("");
    try {
      const responseData = await makeRequest.ownedGames.removeOwned(
        currentGame
      );
      if (!responseData[0].error) {
        setConfirmationMessage(responseData[0].message);
        setErrorMessage("");
        setIsOwned(false);
        setIsLoading(false);
      } else {
        setConfirmationMessage("");
        setErrorMessage(responseData[0].error);
        setIsLoading(false);
      }
    } catch (error: any) {
      setIsLoading(false);
      setErrorMessage(error.message);
    }
  }

  return (
    <div className="backgroundDimmer">
      <div className="gamePopUpMenu">
        <div className="gamePopUpMenu__header">
          <h3>{props.name}</h3>
          <div
            onClick={() => {
              props.setShowOptions(!props.showOptions);
            }}
          >
            <IconCancel />
          </div>
        </div>
        <div className="gamePopUpMenu__list">
          <NotificationErrorBanner errorMessage={errorMessage} />
          <NotificationConfirmationBanner message={confirmationMessage} />

          {isLoading ? <Loader /> : null}

          {isOwned && !isLoading ? (
            <input
              className="buttonRemoveOwned"
              type="button"
              value={"You own this game"}
              onClick={() => removeOwned()}
            />
          ) : (
            <input
              className="buttonAddOwned"
              type="button"
              value={"Add Owned"}
              onClick={() => addOwned()}
            />
          )}

          {/* <Link to={`/games/${props.gameid}`}>See game details</Link> */}
        </div>
      </div>
    </div>
  );
}

export default GamePopUpMenu;
