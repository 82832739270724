import React from 'react'

function ImageNotAvailable() {
  return (
    <div className='imageNotAvailable'>
      <div>No Image</div>
    </div>
  )
}

export default ImageNotAvailable