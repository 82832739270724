import React, { useEffect, useState } from "react";
import makeRequest from "../../api";
//components
import Loader from "../atoms/Loader";
import ErrorMessage from "../atoms/ErrorMessage";
import topOneIcon from "../../assets/custom_icons/1top.png";
import topTwoIcon from "../../assets/custom_icons/2top_48x48.png";
import topThreeIcon from "../../assets/custom_icons/3top_48x48.png";
import TopGamesHighlights from "../molecules/TopGamesHighlights";
import UserTopGamesClaps from "./UserTopGamesClaps";

function UserTopGames(props) {
  //Inbound Data
  const [data, setData] = useState(null); //users top games
  const [errorMessage, setErrorMessage] = useState(null);
  //Outbound data
  const userInput = {
    user: {
      userid: props.userData[0].id,
    },
  };
  //Other

  /**
   * fetches the current users's Top games info
   */
  useEffect(() => {
    getTopGames();

    async function getTopGames() {
      try {
        //Making request
        const responseData = await makeRequest.games.getUserTopGamesInfo(
          userInput
        );
        if (!responseData[0].error) {
          setData(responseData);
          setErrorMessage(null);
        } else {
          setData(null);
          setErrorMessage(responseData[0].error);
        }
      } catch (error) {
        return setErrorMessage(error.message);
      }
    }
  }, []);

  /**
   * Creates component to be rendered
   * @returns JSX component
   */
  const Component = () => {
    if (errorMessage) {
      return <ErrorMessage error={errorMessage} />;
    } else if (data) {
      //Creating new empty array that will hold retrieved games
      let topGamesInfo = data[0];

      //Defining cover url and storing them in our new array
      for (const property in topGamesInfo) {
        let game = topGamesInfo[property];
        //Adding "topNumber" property for sorting purposes
        game.topNumber = `${property}`;
        //Defining images URLs as per IGDB directives
        if (game.cover) {
          let newUrl = game.cover.url.split("t_thumb").join("t_cover_big");
          game.newUrl = newUrl;
        }
      }

      return (
        <>
          <div className="topGames">
            <div className="topGames__title">
              <p>
                {props.userData[0].displayName
                  ? props.userData[0].displayName
                  : props.userData[0].userName}
                's <strong>Top 10</strong> Games of All-time
              </p>
            </div>

            <div className="topGames__top1-3">
              <div className="topGames__top1-3__2">
                <TopGamesHighlights top2Data={topGamesInfo.top2} />

                <div className="topGames__top1-3__2__icon">
                  <img src={topTwoIcon} alt={"top 2 ico"} />
                </div>
              </div>

              <div className="topGames__top1-3__1">
                <TopGamesHighlights top1Data={topGamesInfo.top1} />

                <div className="topGames__top1-3__1__icon">
                  <img src={topOneIcon} alt={"top 1 ico"} />
                </div>
              </div>

              <div className="topGames__top1-3__3">
                <TopGamesHighlights top3Data={topGamesInfo.top3} />

                <div className="topGames__top1-3__3__icon">
                  <img src={topThreeIcon} alt={"top 3 ico"} />
                </div>
              </div>
            </div>

            {/* render top 4-10 only if there are games in that range */}
            {topGamesInfo["top" + 4].id ||
            topGamesInfo["top" + 5].id ||
            topGamesInfo["top" + 6].id ||
            topGamesInfo["top" + 7].id ||
            topGamesInfo["top" + 8].id ||
            topGamesInfo["top" + 9].id ||
            topGamesInfo["top" + 10].id ? (
              <div className="topGames__top4-10">
                <div className="topGames__top4-10__4">
                  <TopGamesHighlights top4Data={topGamesInfo.top4} />

                  <div className="topGames__top4-10__4__rank">4</div>
                </div>

                <div className="topGames__top4-10__5">
                  <TopGamesHighlights top5Data={topGamesInfo.top5} />

                  <div className="topGames__top4-10__5__rank">5</div>
                </div>

                <div className="topGames__top4-10__6">
                  <TopGamesHighlights top6Data={topGamesInfo.top6} />

                  <div className="topGames__top4-10__6__rank">6</div>
                </div>

                <div className="topGames__top4-10__7">
                  <TopGamesHighlights top7Data={topGamesInfo.top7} />

                  <div className="topGames__top4-10__7__rank">7</div>
                </div>

                <div className="topGames__top4-10__8">
                  <TopGamesHighlights top8Data={topGamesInfo.top8} />

                  <div className="topGames__top4-10__8__rank">8</div>
                </div>

                <div className="topGames__top4-10__9">
                  <TopGamesHighlights top9Data={topGamesInfo.top9} />

                  <div className="topGames__top4-10__9__rank">9</div>
                </div>

                <div className="topGames__top4-10__10">
                  <TopGamesHighlights top10Data={topGamesInfo.top10} />

                  <div className="topGames__top4-10__10__rank">10</div>
                </div>
              </div>
            ) : null}
          </div>
        </>
      );
    } else {
      return <Loader />;
    }
  };

  return (
    <section className="topGames">
      <Component />

      <div className="topGames__separator">
        <hr />
      </div>

      <UserTopGamesClaps userData={props.userData} />
    </section>
  );
}

export default UserTopGames;
